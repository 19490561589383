body {
    margin: 0;
    padding: 0;
    font-family: 'EB Garamond', Georgia, 'Times New Roman', serif;
}

.form-control {
    font: inherit;
    color: currentColor;
    padding: 6px 7px;
    border: 0;
    border-bottom: 1px solid #444;
    width: 98%;
}

.table-root {
    margin-top: 24px;
    overflow-x: auto;
}

.RaToolbar-mobileToolbar {
    position: relative !important;
}

@media screen and (max-width: 699px) {
    .table-root {
        width: 90vw;
    }
}
